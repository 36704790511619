import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageContent from "../components/PageContent";
import BlogEntryPreview from "../components/BlogEntryPreview"
import TagsNavigation from "../components/TagsNavigation";

class Blog extends React.Component {
  render() {
    const { data } = this.props;
    const pageName = "blog";
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} pageName={pageName}>
        <SEO
          title="All posts"
          keywords={[`blog`, `ruby on rails`, `javascript`, `react`, `devops`, `startup`, `development`]}
        />
        <PageContent>
          {posts.map(({ node }) => {
            return (
              <BlogEntryPreview node={node} key={node.fields.slug} />
            )
          })}
          <TagsNavigation />
        </PageContent>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { collection: { eq: "blog" } },
    sort: { fields: [frontmatter___date], order: DESC }) {
      ...BlogEntryPreview
    }
  }
`
